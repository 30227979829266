
import {
    defineComponent, ref, reactive, PropType
} from 'vue';
import {
    list, ListHeaderItem, ListActionItem
} from '@/components/common/list';
import deviceStatus from '@/components/view/common/device-status/index.vue';
import { overlayFormData } from '@/util/system';
import { getDeviceList, deleteDevice } from '@/api/single/device';
import remove from '@/methods/remove-func';

// 搜索选项：mac、位置、品牌
const searchList = [{
    label: WordList.TabelPersonDeviceInHtmlMAC,
    name: 'MAC'
}, {
    label: WordList.TabelPersonDeviceInHtmlLocation,
    name: 'Location'
}, {
    label: WordList.MulListUpdateListTanleAccount,
    name: 'Sip'
}, {
    label: WordList.TabelPersonDeviceInHtmlOwner,
    name: 'Owner'
}];
// 表格列表
const headers: Array<ListHeaderItem> = [{
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Owner',
    label: WordList.TabelPersonDeviceInHtmlOwner
}, {
    name: 'SipAccount',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Location',
    label: WordList.TabelPersonDeviceInHtmlLocation
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus,
    type: 'customize'
}, {
    name: 'BindingTime',
    label: WordList.PersonaldeviceListTanleBindingTime
}];
export default defineComponent({
    components: {
        list,
        deviceStatus
    },
    props: {
        account: {
            type: [String, null] as PropType<string | null>,
            default: null
        }
    },
    setup(props) {
        const updateToList = ref(0);
        const saveParamInPath = ref(false);
        const search = () => {
            updateToList.value += 1;
            saveParamInPath.value = true;
        };
        // 搜索条件
        const formData = reactive({
            User: props.account ? props.account : '',
            searchKey: 'MAC',
            searchValue: '',
            Brand: 'all',
            row: 10,
            page: 1
        });
        overlayFormData(formData);

        // 表格数据获取
        const tableData = reactive<{row: object[];detail: object[];total: string}>({
            row: [],
            detail: [],
            total: '0'
        });

        // 获取第三方设备数据
        getThirdPartyDeviceApi();
        function getThirdPartyDeviceApi() {
            getDeviceList(formData, (res: {row: object[];detail: object[];total: string}) => {
                tableData.row = res.row;
                tableData.detail = res.detail;
                tableData.total = res.total;
                updateToList.value += 1;
            });
        }

        // action操作 迁移备注：这部分不用迁移 主要是为了适配删除触发点击
        const action: ListActionItem[] = [
            {
                type: 'delete2',
                event(data: { ID: string }) {
                    remove(WordList.TabelDeleteText, () => {
                        deleteDevice({ ID: data.ID }, getThirdPartyDeviceApi);
                    });
                },
                class: 'el-icon-delete delete-icon',
                title: WordList.TabelConfigInHtmlDelete
            }
        ];

        return {
            searchList,
            headers,
            updateToList,
            saveParamInPath,
            search,
            formData,
            tableData,
            getThirdPartyDeviceApi,
            action
        };
    }
});
